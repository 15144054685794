@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body,
#root {
    min-height: 100%;
}


$primaire: rgb(253, 45, 1);
$secondaire: #FFD7D7;
$tertiaire: #4e5166;
$success: rgb(0, 189, 0);
$successHover: rgb(0, 145, 0);

.primary {
    background-color: $tertiaire;
    color: white;

    &:hover {
        background-color: $primaire;
    }
}

.success {
    background-color: $tertiaire;
    color: white;

    &:hover {
        background-color: $success;
    }
}

.messages {
    background-color: $tertiaire;

    &:hover {
        background-color: #3093ef;
    }
}

body {
    font-family: 'Lato', sans-serif;
    background: linear-gradient(270deg, $secondaire, #fff);

}

.card_shadow {
    box-shadow: 0px 2px 15px rgba(15, 15, 15, .25);
}

.card_shadow_hover {
    box-shadow: 0px 5px 20px rgba(15, 15, 15, .30);
}

.content {
    z-index: 1;
}

button {
    background-color: $tertiaire;
    padding: 10px;
    border-radius: 8px;
    border: none;
    color: white;
    transition: all .1s;

    &:hover {
        background-color: #282c45;
        cursor: pointer;
    }

    &:active {
        transform: scale(.96);
    }
}
