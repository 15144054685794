.commentPrompt {
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(50, 50, 50, .1);

    form {
        width: 100%;
    }

    img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin: 10px;
        margin-left: 15px;
    }

    input[type=text] {
        width: calc(100% - 43px);
        border-radius: 16px;
        border: none;
        background-color: rgb(243, 240, 240);
        padding: 8px;
        padding-left: 15px;
    }

    textarea {
        background-color: rgb(243, 240, 240);
        height: 24px;
        // line-height: 32px;
        width: calc(100% - 32px);
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        padding: 12px;
        resize: none;
        border-radius: 24px;
        border: none;
        transition: all .3s;

        &::placeholder {
            line-height: 24px;
        }

    }

}
