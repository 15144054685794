@import "../../../styles/settings.scss";

.newPosts {
    background-color: white;
    margin: 0 20px;
    padding: 10px;
    border-radius: 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    color: $tertiaire;
    cursor: pointer;
    transition: all .3s;
    @extend .card_shadow;

    p {
        margin: 0;
    }

    &:hover {
        @extend .card_shadow_hover;
        background-color: $success;
        color: white;
        transform: scale(1.01);
    }
}

.newPostsFiller {
    height: 40px;
    margin: -10px 0;
}
