.Deconnexion {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0;
    margin: 0;
    border: none;

    i {
        font-size: 5vh;

        @media screen and (max-height: 442px) {
            font-size: 22px;
        }

        @media screen and (min-height: 780px) {
            font-size: 39px;
        }
    }
}
