@import "../../../../styles/settings.scss";

.likeContainer {
    border-top: 1px solid rgba(50, 50, 50, .1);
    display: flex;
    height: 40px;

    @media (max-width: 517px) {
        justify-content: space-between;
    }

    &::before {
        content: '';
        display: block;
    }

    .likeLove {
        display: flex;
        width: 66.66%;

        @media (max-width: 517px) {
            width: 50%;
        }

        @media (max-width: 300px) {
            width: 30%;
        }
    }

    .likes,
    .loves,
    .shares,
    .commenter {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin: 5px;
        background-color: transparent;
        color: $tertiaire;
        transition: all .3s;

        @media (max-width: 517px) {
            width: auto;
        }

        &.actif {
            color: $primaire;
        }

        &.neutralise {
            color: lightgrey;

            &:hover {
                background-color: rgb(240, 239, 239);
                color: grey;
                cursor: pointer;
            }
        }

        &:hover {
            background-color: $secondaire;
            color: $primaire;
            cursor: pointer;
        }
    }

    .commenter {
        width: 33.33%;

        @media (max-width: 517px) {
            width: 50%;
        }

        @media (max-width: 300px) {
            width: 70%;
        }
    }

    .likes,
    .shares,
    .loves {
        width: 50%;
    }
}
