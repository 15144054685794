.commentaires {
    padding: 0 30px;
    border-top: 1px solid rgba(50, 50, 50, .1);
}

.commentaire {
    position: relative;
    margin-top: 10px;
    margin-bottom: 5px;
    // border-left: 1px solid rgba(15, 15, 15, .08);
    border-radius: 15px;
    border-top-left-radius: 25.75px;

    // &::before {
    //     content: "";
    //     z-index: 0;
    //     display: block;
    //     position: absolute;
    //     width: 1px;
    //     background-color: rgb(167, 255, 255);
    //     top: 22px;
    //     bottom: 55px;
    // }

    .commentWrap {
        display: flex;
    }

    .creatorInfo {
        .wrapper {
            width: 100%;
        }

        .legendeCom {
            width: 100%;

            p {
                font-style: italic;
                font-size: 14px;
                font-weight: 300;
                margin: 0;
                margin-top: 2px;
                margin-left: 2px;
            }

            .comment {
                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    margin: 3px 10px;
                    // margin-bottom: 10px;
                    // margin-left: 10px;

                    &:first-child {
                        margin-top: 10px;
                    }

                    &:last-child {
                        margin-bottom: 10px;
                    }
                }
            }
        }

    }


    .card {
        background-color: #f7efef;
        border-top: 1px solid rgba(15, 15, 15, .08);
        border-right: 1px solid rgba(15, 15, 15, .08);
        border-bottom: 1px solid rgba(15, 15, 15, .08);
        border-radius: 25.75px;

        p {
            margin: 10px;
        }
    }

    .blockActions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &>div {
            display: flex;
        }

        .commenter {
            cursor: pointer;
            font-weight: 600;
            transition: transform .1s;

            &:hover {
                transform: scale(1.03);
            }
        }

        p {
            margin: 7px;
            margin-top: 0;
            font-weight: 300;
            font-size: 14px;
        }

        .heure {
            font-style: italic;

            p {
                font-size: 12px;
            }
        }
    }

    .commentPrompt {
        border: none;
    }

    img.imgUser {
        z-index: 1;
        width: 45px;
        height: 45px;
        margin: 0;
        margin-left: -2.1%;
        border-radius: 50%;
    }
}

.prompt {
    background-color: rgb(243, 240, 240);
    height: 24px;
    // line-height: 32px;
    width: calc(100% - 32px);
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    padding: 12px;
    resize: vertical;
    border-radius: 24px;
    border: none;
    transition: all .3s;

    &::placeholder {
        line-height: 24px;
    }

}
