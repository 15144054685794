nav {
    padding-left: 20px;
    height: 9vh;
    max-height: 70px;
    min-height: 40px;
    background: rgba(98, 81, 102, 0.65);
    backdrop-filter: blur(10px);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 571px) {
        top: 0;
        bottom: auto;
    }

    .logo {
        max-width: 267px;
        min-width: 111px;
        font-size: 6.6px;

        h1 {
            color: white;
        }
    }

    @media screen and (min-width: 297px) {
        .logo {
            font-size: calc(6.6px + 8.4 * ((100vw - 297px) / 126));
        }
    }

    @media screen and (min-width: 423px) {
        .logo {
            font-size: 15px;
        }
    }

    .nav-logo {
        fill: white;
        transition: all .3s;

        &:hover {
            fill: #fd2d01;
        }
    }

    ul {
        list-style: none;
        display: flex;
        align-items: center;
        padding-left: 20px;

        li:nth-child(2) {
            margin: 0px 20px;
        }
    }

    .nav-link {
        color: white;
        text-decoration: none;
        transition: color 0.3s;

        img {
            width: 6vh;
            height: 6vh;
            max-width: 47px;
            max-height: 47px;
            min-width: 26.5px;
            min-height: 26.5px;
            border-radius: 50%;
            transition: box-shadow .3s;

            &:hover {
                box-shadow: 0px 0px 0px .5vh rgba(253, 45, 1, .85);
            }
        }

        &:hover {
            color: #fd2d01;
        }

        &.active {
            color: #ffd1c8;
        }

        button {
            transition: color 0.3s;

            &:hover {
                color: #fd2d01;
                background-color: transparent;
            }
        }
    }

    i {
        font-size: 24px;
    }
}
