.img-container {
    position: fixed;
    // z-index: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .svg-box {
        position: relative;
        margin: auto;
    }

    svg {
        fill: url(#logoGradient);
        width: 90vw;
        max-width: 50vh;
    }
}
