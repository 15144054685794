.login {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    margin: 0 auto;

    .loginModal {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
