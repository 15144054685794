.home {
    display: flex;
    flex-direction: column;
    max-width: 1160px;
    margin: 0 auto;

    .wallContainer {
        width: calc(100% - 450px);
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: row;
    }

    h1 {
        text-align: center;
    }

    .content {
        @media (max-width: 571px) {
            margin-bottom: 9vh;

            @media screen and (min-height: 780px) {
                margin-bottom: 70px;
            }

            @media screen and (max-height: 442px) {
                margin-bottom: 40px;
            }
        }


        @media (min-width: 571px) {
            margin-top: 9vh;

            @media screen and (min-height: 780px) {
                margin-top: 70px;
            }

            @media screen and (max-height: 442px) {
                margin-top: 40px;
            }
        }
    }

}
