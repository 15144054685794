.reponse {
    position: relative;
    margin-left: 2%;
    margin-bottom: 5px;
    border-radius: 15px;
    border-top-left-radius: 25.75px;
    // border-left: 1px solid rgba(15, 15, 15, .08);

    // &::before {
    //     content: "";
    //     z-index: 0;
    //     display: flex;
    //     position: absolute;
    //     width: 1px;
    //     background-color: rgb(167, 255, 255);
    //     top: 22px;
    //     bottom: 55px;
    // }
    span {
        font-weight: 300;
    }

    .card {
        width: 100%;

        p {
            margin: 10px;
        }
    }
}
