@import "../../../../styles/settings.scss";

.postForm {
    position: relative;
    min-height: 300px;
    width: calc(100% - 40px);
    margin: 20px;
    border-radius: 15px;
    background-color: rgba(240, 240, 240, .8);
    backdrop-filter: blur(10px);

    @media (max-width: 500px) {
        margin: auto;
        margin-top: 5px;
        width: 95%
    }

    .success {
        position: absolute;
        bottom: 28px;
        right: 10px;
        background-color: $success;

        &:hover {
            background-color: $successHover;
        }
    }

    .input-file {
        display: none;
    }

    .label-file {
        position: absolute;
        bottom: 25px;
        right: 100px;
        cursor: pointer;
        font-size: 40px;
        color: $secondaire;

        &:hover {
            color: #b28a8a;
        }

        &:active {
            transform: scale(.95);
        }
    }

    textarea {
        background-color: rgba(255, 255, 255, .7);
        height: 160px;
        font-size: 22px;
        font-weight: 300;
        padding: 15px;
        width: calc(100% - 30px);
        resize: none;
        position: absolute;
        border-radius: 15px;
        top: 0;
        left: 0;
        right: 0;
        border: none;
        transition: all .3s;

        &::placeholder {
            text-align: center;
            line-height: 149px;
        }

        &:focus {
            background-color: rgba(255, 255, 255, 1);
            @extend .card_shadow_hover;
            transform: scale(1.01);
            border-color: $secondaire;
        }
    }

    img {
        object-fit: contain;
        position: absolute;
        border-radius: 8px;
        width: auto;
        bottom: 5px;
        left: 5px;
        height: 100px;
        @extend .card_shadow;

        &:hover {
            @extend .card_shadow_hover;
        }
    }

    @extend .card_shadow;
    transition: all .3s;

    &:hover {
        @extend .card_shadow_hover;
    }

}
