@import "../../../../styles/settings.scss";

.singlePost {
    background-color: rgba(255, 255, 255, 1);
    // backdrop-filter: blur(10px);
    border-radius: 15px;
    margin: 20px;
    // padding: 15px;

    @media (max-width: 500px) {
        margin: 20px 0;
    }


    .updatePost {
        font-size: 16px;
        // resize: vertical;
    }

    .creatorInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .legende {
            p {
                font-style: italic;
                font-size: 16px;
                font-weight: 300;
                margin: 0;
            }
        }

    }

    .wrapper {
        display: flex;
        align-items: center;
    }

    .modifierOuSupprimer {
        background-color: transparent;
        color: $tertiaire;
        transition: all .3s;
        border-radius: 50%;
        padding: 10px 12.2px;
        margin-right: 10px;

        &:hover {
            color: $primaire;
            @extend .card_shadow
        }
    }

    .mod-supp {
        background-color: transparent;
        color: $tertiaire;
        transition: all .3s;
        padding: 10px 12.2px;
        margin-right: 10px;

        &:hover {
            color: $primaire;
            @extend .card_shadow
        }
    }

    .shared {
        padding-left: 2%;
        border-top: 1px solid rgba(50, 50, 50, .1);
    }

    .imgUser {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin: 10px;
    }

    .postContainer {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;

        p {
            color: $tertiaire;
            padding: 15px;
            margin: 0;
            font-weight: 300;
            font-size: 17px;
        }

        .imgPost {
            margin-left: auto;
            object-fit: cover;
            width: 100%;
            border-top: 1px solid rgba(50, 50, 50, .1);
            border-bottom: 1px solid rgba(50, 50, 50, .1);
        }
    }

    @extend .card_shadow;
    transition: all .3s;

    &:hover {
        @extend .card_shadow_hover;
    }
}
