@import "../../../../styles/settings.scss";

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: all .3s;
    margin: 5px;

    &:hover {
        cursor: pointer;
        @extend .card_shadow;
        transform: scale(1.005);
    }
}

.modifierOuSupprimer {
    background-color: transparent;
    color: $tertiaire;
    transition: all .3s;
    border-radius: 50%;
    padding: 5px 7.2px;

    &:hover {
        color: $primaire;
        @extend .card_shadow
    }
}

.aUserContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

.amis {
    display: flex;
    overflow-x: scroll;
}

.aUser {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    min-width: 80px;
    max-width: 100px;
    transition: all .3s;

    &:hover {
        cursor: pointer;
        @extend .card_shadow;
        transform: scale(1.005);
    }

    p {
        text-align: center;
        margin: 8px 0;
        font-weight: 300;
    }

    .choix {
        display: flex;
    }

    .btnFollow {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;
        transition: all .3s;
    }

    // .userCard {
    //     display: none;
    //     position: absolute;
    //     z-index: 1;
    //     bottom: 45px;
    //     width: 200px;
    //     height: 100px;
    //     background-color: rgba(255, 255, 255, .8);
    //     border-radius: 15px;
    //     backdrop-filter: blur(10px);
    //     padding: 20px;
    // }

    // &:hover .userCard {
    //     display: block;
    // }
}

.avatar {
    width: 50px;
    border-radius: 50%;
    transition: all .3s;
    margin: 5px;

    // &:hover {
    //     cursor: pointer;
    //     @extend .card_shadow;
    //     transform: scale(1.005);
    // }
}

.utilisateurs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: blur(10px);
    padding: 20px;
    margin: 20px;
    max-height: 500px;
    overflow-y: scroll;
    border-radius: 15px;
    width: 370px;
    transition: all .3s;
    @extend .card_shadow;

    &:hover {
        @extend .card_shadow_hover;
        transform: scale(1.005);
    }

    .userContainer {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h2 {
        width: 370px;
        text-align: center;
        font-weight: 400;
        color: #7c7c85;
    }

}

.UserInfo {
    background-color: rgba(255, 255, 255, .8);
    box-sizing: content-box;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 370px;
    padding: 20px;
    margin: 20px;
    border-radius: 15px;
    transition: all .3s;
    @extend .card_shadow;

    h2 {
        text-align: center;
        font-weight: 500;
    }

    p {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 0;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 370px;
    }

    input[type=text] {
        background-color: rgba(255, 255, 255, .7);
        backdrop-filter: blur(10px);
        border-radius: 8px;
        padding: 10px;
        border: none;
        width: 270px;
        transition: all .3s;
        @extend .card_shadow;

        &::placeholder {
            font-size: 18px;
            font-weight: 300;
        }

        &:hover,
        &:focus {
            @extend .card_shadow_hover;
            transform: scale(1.005);
        }
    }

    textarea {
        background-color: rgba(255, 255, 255, .7);
        font-size: 18px;
        font-weight: 300;
        padding: 15px;
        width: 270px;
        resize: vertical;
        border-radius: 15px;
        top: 0;
        left: 0;
        right: 0;
        border: none;
        transition: all .3s;
        @extend .card_shadow;

        // &::placeholder {
        //     text-align: center;
        // }

        &:focus {
            background-color: rgba(255, 255, 255, 1);
            @extend .card_shadow_hover;
            transform: scale(1.01);
            border-color: $secondaire;
        }
    }


    img {
        border-radius: 50%;
        margin-bottom: 20px;
    }

    &:hover {
        @extend .card_shadow_hover;
        transform: scale(1.005);
    }

    button {
        margin-top: 20px;
        width: 200px;
    }

    button.success {
        background-color: $success;
        margin-bottom: -10px;

        &:hover {
            background-color: $successHover;
        }
    }

    .label-file {
        cursor: pointer;
        color: white;
        font-weight: bold;
        background-color: $tertiaire;
        text-align: center;
        padding: 10px;
        border-radius: 8px;
        transition: all .1s;

        &:hover {
            background-color: #282c45;
        }

        &:active {
            transform: scale(.95);
        }
    }

    .input-file {
        display: none;
    }

    .error {
        text-align: center;
        background-color: $primaire;
        color: white;
        max-width: 370px;
        padding: 8px;
        border-radius: 8px;
    }


}
